import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

@Pipe({
	name: 'daysAgo',
})
export class DaysAgoPipe implements PipeTransform {
	constructor() {
		dayjs.extend(relativeTime);
	}
	transform(value: string): string {
		return dayjs().to(dayjs(value));
	}
}
