import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageKeys } from 'src/app/consts/storage-keys';
import { Product } from 'src/app/interfaces/product';
import { UserService } from 'src/app/services/user/user.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-product',
	templateUrl: './product.component.html',
	styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
	imageBaseUrl: string = environment.imageUrl;
	selectedProductVariant: string = '';

	productPrice: number = 0;
	productDiscountPrice: number = 0;
	isProductInStock: boolean = false;

	@Input('product') product: any;
	@Input('showSummary') showSummary: boolean = false;

	stars = [1, 2, 3, 4, 5];

	constructor(
		public userService: UserService,
		private router: Router,
		private utilityService: UtilityService,
	) { }

	ngOnInit(): void {
		this.selectedProductVariant = this.product.product_variant[0].id;
		this.isProductInStock = this.product.product_variant[0].variant_quantity > 0 ? true : false;
		this.productPrice = this.product.product_variant[0].price_with_tax;
		this.productDiscountPrice =
			this.product.product_variant[0].discount_price_with_tax;
	}

	onProductVariantChange(event: any) {
		this.product.product_variant.find((item: any) => {
			if (item.id == event.target.value) {
				this.productPrice = item.price_with_tax;
				this.productDiscountPrice = item.discount_price_with_tax;
				this.isProductInStock = item.variant_quantity > 0 ? true : false;
			}
		});
	}

	addToCart() {
		let {
			id,
			product_name,
			category_id,
			category_name,
			product_variant,
			product_images,
			product_main_image,
		} = this.product;
		let selectedProductVariantData;
		product_variant.find((item: any) => {
			if (item.id == this.selectedProductVariant) {
				selectedProductVariantData = item;
			}
		});

		let productData = {
			id,
			product_name,
			category_id,
			category_name,
			product_image: product_main_image,
			quantity: 1,
			product_variant: selectedProductVariantData,
		};
		let cartData = [];

		const existingCart = localStorage.getItem(StorageKeys.CART);

		if (existingCart) {
			cartData = JSON.parse(existingCart);
			let isProductExist = false;

			cartData.map((product: any) => {
				if (product.id == id) {
					if (product.product_variant.id == this.selectedProductVariant) {
						product.quantity += 1;
						isProductExist = true;
					}
				}
			});

			if (!isProductExist) {
				cartData.push(productData);
			}

			localStorage.setItem(StorageKeys.CART, JSON.stringify(cartData));
		} else {
			cartData.push(productData);
			localStorage.setItem(StorageKeys.CART, JSON.stringify(cartData));
		}
		this.userService.onCartUpdate.emit();
	}

	preOrder() {
		let {
			id,
			product_name,
			category_id,
			category_name,
			product_variant,
			product_main_image,
		} = this.product;
		let selectedProductVariantData;
		product_variant.find((item: any) => {
			if (item.id == this.selectedProductVariant) {
				selectedProductVariantData = item;
			}
		});

		let productData = {
			id,
			product_name,
			category_id,
			category_name,
			product_image: product_main_image,
			quantity: 1,
			product_variant: selectedProductVariantData,
		};
		let cartData = [];

		// const existingCart = localStorage.getItem(StorageKeys.PRE_ORDER);
		// if (existingCart) {
		// 	cartData = JSON.parse(existingCart);
		// 	let isProductExist = false;
		// 	cartData.map((product: any) => {
		// 		if (product.id == id) {
		// 			if (product.product_variant.id == this.selectedProductVariant) {
		// 				product.quantity += this.quantity;
		// 				isProductExist = true;
		// 			}
		// 		}
		// 	});

		// 	if (!isProductExist) {
		// 		cartData.push(productData);
		// 	}

		// 	localStorage.setItem(StorageKeys.PRE_ORDER, JSON.stringify(cartData));
		// 	this.goToCheckout();
		// } else {
		cartData.push(productData);
		localStorage.setItem(StorageKeys.PRE_ORDER, JSON.stringify(cartData));
		this.goToCheckout();
		// }
	}

	goToCheckout() {
		if (this.userService.isLogIn) {
			this.router.navigate(['/checkout'], {
				queryParams: { 'early': true }
			});
		} else {
			this.utilityService.showInfoToast('Please login to proceed checkout.');
			this.router.navigate(['/sign-in'], { queryParams: { ref: 'cart', 'early': true } });
		}
	}
}
