<div class="flex flex-col gap-4">
	<div class="flex flex-col gap-1">
		Rating:
		<p-rating [(ngModel)]="rating" [cancel]="false">
		</p-rating>
	</div>

	<div class="flex flex-col gap-1">
		Write your review:
		<textarea rows="5" cols="30" pInputTextarea [(ngModel)]="review" class="input-style w-full"></textarea>
	</div>

	<button type="button" class="primary-button self-end" (click)="submitRatingAndReview()">
		Submit
	</button>
</div>