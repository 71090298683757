import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
	selector: 'app-add-review',
	templateUrl: './add-review.component.html',
	styleUrls: ['./add-review.component.scss']
})
export class AddReviewComponent {
	review: string = '';
	// stars: number[] = [1, 2, 3, 4, 5];
	rating: number = 0;

	constructor(
		private utilityService: UtilityService,
		public ref: DynamicDialogRef,
	) { }

	submitRatingAndReview() {
		const params = {
			rating: this.rating,
			review: this.review,
		};
		this.ref.close(params);
	}
}
