<div class="text-center justify-center content-center items-center flex flex-col gap-2">
	<img src="/assets/images/logo.jpg" alt="logo"
		 class="h-[100px] flex justify-center content-center items-center m-auto mb-7">
	<h3 class="text-lg font-semibold popup-text">This website is exclusively for individuals who are of <br>legal adult age (21+).</h3>
	<p class="popup-text">Are you over 21 years of age?</p>
	<div class="mt-6 flex gap-4 items-center justify-center">
		<a href="https://www.google.com"
		   class="bg-primary outline-none w-32 border-0 mb-0 text-black rounded-full px-4 py-1 text-center">No</a>
		<button (click)="close()" class="bg-secondary w-32 mb-0 text-white rounded-full px-4 py-1">Yes</button>
	</div>
</div>