import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';

@Pipe({
	name: 'calendarTime',
})
export class CalendarTimePipe implements PipeTransform {
	constructor() {
		dayjs.extend(calendar);
	}
	transform(value: string): string {
		return dayjs(value).calendar(null, {
			sameDay: '[Today at] h:mm A', // The same day ( Today at 2:30 AM )
			nextDay: '[Tomorrow at] h:mm A', // The next day ( Tomorrow at 2:30 AM )
			nextWeek: 'dddd [at] h:mm A', // The next week ( Sunday at 2:30 AM )
			lastDay: '[Yesterday at] h:mm A', // The day before ( Yesterday at 2:30 AM )
			lastWeek: '[Last] dddd [at] h:mm A', // Last week ( Last Monday at 2:30 AM )
			sameElse: 'DD MMMM, YYYY [at] h:mm A', // Everything else ( 17/10/2011 )
		});
	}
}
