import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeCharacters',
})
export class RemoveCharactersPipe implements PipeTransform {
  transform(value: string, charactersToRemove: string): string {
    if (!value || !charactersToRemove) {
      return value;
    }
    return value.replace(new RegExp(charactersToRemove, 'g'), '');
  }
}
