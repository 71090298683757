import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'badge',
})
export class BadgePipe implements PipeTransform {
  transform(value: number): any {
    if (value < 20) {
      return 'Fresh Meat';
    } else if (value > 20 && value <= 100) {
      return 'Community Contributor';
    } else if (value > 100 && value <= 500) {
      return 'Trooper';
    } else if (value > 500 && value <= 1000) {
      return 'Super Trooper';
    } else if (value > 1000 && value <= 5000) {
      return 'Guru';
    } else if (value > 5000 && value <= 10000) {
      return 'Supreme Leader';
    } else if (value > 10000 && value <= 25000) {
      return 'Enlightened Being';
    } else if (value >= 25001) {
      return 'Immortal';
    }
  }
}
