import { Pipe, PipeTransform } from '@angular/core';
declare var DOMPurify: any
@Pipe({
	name: 'stripHtml'
})
export class StripHtmlPipe implements PipeTransform {
	transform(value: string, ...args: unknown[]): string {
		// return value.replace(/<.*?>/g, '');
		const userInputHTML = '<figure class="image"><img style="aspect-ratio:1000/1000;" src="https://fakeimg.pl/300/" width="1000" height="1000"></figure>';
		const result = this.extractSrcFromImgTags(value);
		return result;
	}

	sanitizeHTML(htmlString: string) {
		// Create a new DOMPurify instance if not already available
		if (typeof DOMPurify === 'undefined') {
			throw new Error('DOMPurify is required for sanitizing input.');
		}

		// Sanitize the HTML to remove potentially harmful code
		return DOMPurify.sanitize(htmlString);
	}

	extractSrcFromImgTags(htmlString: string) {
		// Sanitize the input HTML
		const sanitizedHTML = this.sanitizeHTML(htmlString);

		// Use a temporary element to hold the sanitized HTML
		const tempDiv = document.createElement('div');
		tempDiv.innerHTML = sanitizedHTML;

		// Extract <img> elements
		const imgTags = tempDiv.querySelectorAll('img');

		// Extract src values and replace <img> tags
		let plainText = sanitizedHTML;
		imgTags.forEach(img => {
			const src = img.getAttribute('src');
			if (src) {
				// Replace <img> tag with its src value
				plainText = plainText.replace(/<img[^>]*>/i, src);
			}
		});

		// Remove any remaining HTML tags
		plainText = plainText.replace(/<\/?[^>]+>/gi, '');
		return plainText.trim();
	}

}
