import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { API } from 'src/app/consts/Api';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  supplierPanelUrl = environment.supplierPanelUrl;
  windowScrolled: any;
  scrollToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
  year: number = 0;
  subscribeForm: FormGroup;
  @Input('brands') brands: any;

  constructor(private http: HttpService, private utility: UtilityService) {
    this.year = new Date().getFullYear();
    this.subscribeForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }
  ngOnInit() {
    window.addEventListener('scroll', () => {
      this.windowScrolled = window.pageYOffset !== 0;
    });
  }

  get email() {
    return this.subscribeForm.get('email');
  }

  subscribeForNewsLatter() {
    if (this.subscribeForm.valid) {
      this.http
        .post(API.SUBSCRIBE, {
          email: this.subscribeForm.value.email,
        })
        .subscribe({
          next: (res: any) => {
            this.utility.showSuccessToast(res.data);
          },
          error: (err) => {
            this.utility.showErrorToast(err.error.message);
          },
        });
    } else {
      this.utility.showErrorToast('Enter valid email address.');
    }
  }
}
