<div class="product cursor-pointer rounded border border-gray">
	<div class="w-full space-y-1 px-2 py-2">
		<p class="text-[#272422] capitalize font-medium text-base h-auto line-clamp-1" [routerLink]="['/product', product.id]" [pTooltip]="product.product_name">
			{{ product.product_name }}
		</p>

		<p class="mt-[-4px]">
			<i class="fa fa-star text-[#c4c4c4] cursor-pointer text-sm leading-6"
			   [ngClass]="{ '!text-[#ecc028]': star <= product.rating }" *ngFor="let star of stars">
			</i>
			<span class="text-sm italic text-[#c4c4c4]">({{ product.rating_count }})</span>
		</p>
		<!-- {{((product.rating) ? product.rating: 0) | number: '1.2-2'}} ({{product.rating_count}}) -->
		<p class="text-sm text-[#9d9ea3]">
			<select class="border border-[#979797] rounded-md bg-transparent px-2 h-8 w-full cursor-pointer outline-none" (change)="onProductVariantChange($event)" [(ngModel)]="selectedProductVariant">
				<option [value]="variant.id" *ngFor="
                        let variant of product.product_variant;
                        let i = index
                    ">
					{{ variant.variant_name }} seeds
				</option>
			</select>
		</p>

		<div class="flex justify-between text-base font-medium">
			<div>
				<span [ngClass]="{'line-through': productDiscountPrice > 0 && productDiscountPrice < productPrice }">
					${{ productPrice | number : "1.2-2" }}
				</span>
				&nbsp;
				<span *ngIf="productDiscountPrice > 0 && productDiscountPrice < productPrice">
					${{ productDiscountPrice | number : "1.2-2" }}
				</span>
			</div>
			<a [href]="imageBaseUrl + product.grow_report" target="_blank" rel="noopener noreferrer" class="flex items-center gap-1 font-medium cursor-pointer underline" *ngIf="product.quality_tested == '1' && product.grow_report && product.grow_report != 'null'" [pTooltip]="product.grow_report">
				<img src="/assets/icons/pdf-file-icon.svg" [alt]="'Download Grow Report of ' + product.product_name" class="h-[18px] w-[18px] pointer-events-none select-none object-contain" />
			</a>
		</div>

		<div *ngIf="isProductInStock">
			<button class="text-xs font-semibold bg-secondary hover:bg-secondary-light text-white px-2 py-2 cursor-pointer w-full rounded-[4px]" (click)="
                    $event.stopPropagation();
                    isProductInStock ? addToCart() : null
                " *ngIf="product.allow_pre_order != '1'">
				Add to Cart
			</button>

			<button class="text-xs font-semibold bg-blue-500 hover:bg-blue-600 text-white px-2 py-2 cursor-pointer w-full rounded-[4px]" (click)="
                    $event.stopPropagation();
                    isProductInStock ? preOrder() : null
                " *ngIf="product.allow_pre_order == '1'">
				Pre Order
			</button>
		</div>

		<div *ngIf="!isProductInStock" class="text-xs font-semibold bg-red-500 text-white px-2 py-2 cursor-not-allowed w-full rounded-[4px]">
			<p class="m-0 p-0 font-semibold text-center">Out Of Stock</p>
		</div>
	</div>
	<img [src]="imageBaseUrl + product.product_main_image" [alt]="product.product_name" class="product-image" onError="this.src = '/assets/images/placeholder-image.webp'" [routerLink]="['/product', product.id]" />
	<div *ngIf="showSummary" class="pt-1">
		<div class="w-full text-sm">
			<div class="summary">
				<span>Grow Type:</span>
				<span class="line-clamp-1">{{ product.grow_type }}</span>
			</div>
			<div class="summary">
				<span>Flowering:</span>
				<span class="line-clamp-1">{{ product.flowering }}</span>
			</div>
			<div class="summary">
				<span>Climate:</span>
				<span class="line-clamp-1">{{ product.climate }}</span>
			</div>
			<div class="summary">
				<span>Classification:</span>
				<span class="line-clamp-1">{{ product.landrace }}</span>
			</div>
			<div class="summary">
				<span>THC:</span>
				<span class="line-clamp-1">{{ product.thc }}</span>
			</div>
			<div class="summary">
				<span>CBD:</span>
				<span class="line-clamp-1">{{ product.cbd }}</span>
			</div>
			<div class="bg-[#e2e2e2] px-2 py-2">
				<span class="border-b pb-[0px] text-xs sm:text-[14px] leading-[2]">Harvest Date By Latitude:</span>
				<span class="block sm:pt-[12px] pt-[4px]" [innerHTML]="
                        !product.harvest_date_by_latitude ||
                        product.harvest_date_by_latitude != 'null'
                            ? product.harvest_date_by_latitude
                            : '-'
                    "></span>
			</div>
		</div>
	</div>
</div>