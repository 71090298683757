import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-stock-indicator',
	templateUrl: './stock-indicator.component.html',
	styleUrls: ['./stock-indicator.component.scss']
})
export class StockIndicatorComponent {
	@Input('allowPreOrder') allowPreOrder: any;
	@Input('stockQuantity') stockQuantity: number = 0;
	@Input('isProductInStock') isProductInStock: boolean = true;
}
