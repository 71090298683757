<div class="footer-container relative z-20">
	<div class="mx-4 box-border lg:w-full lg:max-w-[1100px] lg:mx-auto px-4 lg:px-10 py-8 bg-secondary rounded-2xl">
		<div class="pb-4 border-b border-white-500">
			<p class="text-white">
				<span class="font-semibold">Disclaimer:</span>
				All seeds are strictly for novelty and souvenir purposes and are not intended for cultivation or
				gemination. All
				Cannabis seed is federally legal in the USA, regardless of chemotype, as the seeds themselves contain
				less than 0.3% THC
				and are therefore legally considered hemp. You must be over the age of 21 to purchase this product. The
				planting of
				these seeds may or may not be legal in your jurisdiction. It is therefore important before purchasing
				this product that
				you check if its use complies with local laws and regulations. We hold no liability for any breach of
				local laws of misuse of this product.
			</p>
		</div>

		<div class="flex flex-col sm:flex-row items-center mt-2 gap-8 justify-between">
			<div class="w-full sm:w-2/5 flex flex-col text-white">
				<h3 class="text-3xl font-medium leading-loose">Newsletter</h3>
				<p class="font-territory-font text-lg">
					Subscribe to our newsletter <br />to stay up to date at all times.
				</p>
			</div>

			<form [formGroup]="subscribeForm" (ngSubmit)="subscribeForNewsLatter()"
				class="flex rounded-full bg-white py-2 px-2 justify-between gap-2 w-full sm:w-1/2">
				<input type="email" name="email" placeholder="Email" formControlName="email" required
					class="w-full outline-none pl-2" />
				<!-- <span *ngIf="email?.invalid && (email?.dirty || email?.touched)" class="form-error">
                    <span *ngIf="email?.errors?.['required']">
                        Email is required
                    </span>
                    <span *ngIf="email?.errors?.['email']">
                        Email is invalid
                    </span>
                </span> -->
				<button type="submit" class="bg-secondary text-white rounded-full px-4 py-1">
					Subscribe
				</button>
			</form>
		</div>
	</div>

	<div
		class="grid grid-cols-2 gap-6 sm:gap-0 sm:flex justify-between max-w-[1100px] mx-auto py-8 px-4 items-start content-center place-content-center">
		<div>
			<h4 class="font-bold mb-1">Forum, Breeders</h4>
			<ul>
				<li><a routerLink="/forum">Forums</a></li>
				<li><a routerLink="/suppliers">Breeders</a></li>
			</ul>
			<h4 class="font-bold pt-6 mb-1">Other Pages</h4>
			<ul>
				<li><a routerLink="/">Home</a></li>
				<!-- <li><a routerLink="/blog">Blog</a></li> -->
				<li><a routerLink="/about-us">About Us</a></li>
				<li><a routerLink="/cart">Cart</a></li>
				<li>
					<a href="https://www.instagram.com/starsandstripesseed/" target="_blank"
						rel=”noreferrer”>Instagram</a>
				</li>
				<li><a href="https://twitter.com/StarStripesSeed" target="_blank" rel=”noreferrer”>X</a></li>
			</ul>
		</div>
		<div>
			<h4 class="font-bold mb-1">Cultivars</h4>
			<ul class="flex flex-col">
				<ng-container *ngFor="let b of brands">
					<a routerLink="/brand-products/{{ b.id }}" class="quick-item" *ngIf="b.position == 'R'">
						{{ b.brand_name }}
					</a>
				</ng-container>
				<div class="border-t mt-2 mb-2"></div>
				<ng-container *ngFor="let b of brands">
					<a routerLink="/brand-products/{{ b.id }}" class="quick-item" *ngIf="b.position == 'L'">
						{{ b.brand_name }}
					</a>
				</ng-container>
			</ul>
		</div>

		<div>
			<h4 class="font-bold mb-1">Register or Sign In</h4>
			<ul>
				<!-- <li><a routerLink="/terms">Terms & Condition</a></li>
					            <li><a routerLink="/privacy-policy">Privacy Policy</a></li> -->
				<li>
					<a href="/sign-in" rel=”noreferrer”>
						Sign in User</a>
				</li>
				<li>
					<a href="/tester-sign-in" rel=”noreferrer”>Sign in Tester</a>
				</li>
				<li>
					<a [href]="supplierPanelUrl" target="_blank" rel=”noreferrer”>sign in Breeder</a>
				</li>
				<li class="mt-4"><a routerLink="/sign-up">Register as User</a></li>
				<li><a routerLink="/become-supplier">Register as Breeder</a></li>
			</ul>
		</div>
		<div>
			<h4 class="font-bold mb-1">Support</h4>
			<ul>
				<!-- <li><a routerLink="/terms">Terms & Condition</a></li>
			            <li><a routerLink="/privacy-policy">Privacy Policy</a></li> -->
				<li>
					<a href="/assets/terms-and-conditions-SSSC.pdf" target="_blank" rel=”noreferrer”>Terms &
						Condition</a>
				</li>
				<li>
					<a href="/assets/privacy-policy-SSSC.pdf" target="_blank" rel=”noreferrer”>Privacy Policy</a>
				</li>
				<li>
					<a routerLink="/faq">FAQ</a>
				</li>
				<li><a routerLink="/contact-us">Contact Us</a></li>
			</ul>
		</div>
	</div>
	<footer>
		<div class="text-center py-2">
			<img alt="accepted-payment" src="assets/images/accepted-cards.png"
				class="h-[30px] w-auto items-center m-auto">
			&copy; {{ year }} Stars and Stripes Seed. All right Reserved.
		</div>
	</footer>
	<div class="to-top bg-secondary text-white rounded-full text-center flex items-center justify-center shadow-md"
		(click)="scrollToTop()" [ngClass]="{ 'show-scrollTop': windowScrolled }">
		<span class="material-symbols-rounded">
			expand_less
		</span>
	</div>
</div>