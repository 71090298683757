import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
@Component({
	selector: 'app-age-restriction',
	templateUrl: './age-restriction.component.html',
	styleUrls: ['./age-restriction.component.scss']
})

export class AgeRestrictionComponent {
	constructor(private ref: DynamicDialogRef) { }
	close() {
		this.ref.close()
	}
}
