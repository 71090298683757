import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { countries } from 'src/app/consts/countries';

@Component({
	selector: 'app-edit-address',
	templateUrl: './edit-address.component.html',
	styleUrls: ['./edit-address.component.scss'],
})
export class EditAddressComponent implements OnInit {
	addressFormGroup: FormGroup;
	countryList = countries;
	constructor(
		public dialogRef: DynamicDialogRef,
		public dialogConfig: DynamicDialogConfig,
	) {
		const { address } = this.dialogConfig.data;
		const { address_1, address_2, country, state, city, zip, landmark } = address;
		console.log(country);

		this.addressFormGroup = new FormGroup({
			address_1: new FormControl(address_1, Validators.required),
			address_2: new FormControl(address_2, Validators.required),
			country: new FormControl(country, Validators.required),
			state: new FormControl(state, Validators.required),
			city: new FormControl(city, Validators.required),
			zip: new FormControl(zip, Validators.required),
			landmark: new FormControl(landmark),
		});
	}

	ngOnInit(): void { }

	saveAddress() {
		if (this.addressFormGroup.valid) {
			this.dialogRef.close(this.addressFormGroup.value);
		}
	}
}
