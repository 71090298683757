<div>
	<form [formGroup]="addressFormGroup" (ngSubmit)="saveAddress()">
		<div>
			<div class="flex flex-col space-y-1">
				<label for="address1">House No / Flat Number</label>
				<input type="text" formControlName="address_1" name="address_1" id="address1" placeholder="Address 1"
					   required
					   class="border-2 border-primary-light rounded-md px-1 py-2 placeholder:italic focus:outline-none focus-visible:border-primary-dark" />
			</div>

			<div class="flex flex-col space-y-1 mt-6">
				<label for="address2">Street / Road</label>
				<input type="text" formControlName="address_2" name="address_2" id="address2" placeholder="Address 2"
					   required
					   class="border-2 border-primary-light rounded-md px-1 py-2 placeholder:italic focus:outline-none focus-visible:border-primary-dark" />
			</div>

			<div class="flex gap-2">
				<div class="flex-[1] flex flex-col space-y-1 mt-6 w-full">
					<label for="country">Country</label>
					<select formControlName="country" name="country" id="country" required
							class="border-2 border-primary-light rounded-md px-1 py-2 placeholder:italic focus:outline-none focus-visible:border-primary-dark w-full">
						<option value="">Select Country</option>
						<option [value]="c.code" *ngFor="let c of countryList">
							{{ c.name }}
						</option>
					</select>
				</div>

				<div class="flex-[1] flex flex-col space-y-1 mt-6 w-full">
					<label for="state">State</label>
					<input type="text" formControlName="state" name="state" id="state" placeholder="State" required
						   class="border-2 border-primary-light rounded-md px-1 py-2 placeholder:italic focus:outline-none focus-visible:border-primary-dark" />
				</div>
			</div>

			<div class="flex gap-2">
				<div class="flex-1 flex flex-col space-y-1 mt-6">
					<label for="mobile">City</label>
					<input type="text" formControlName="city" name="city" id="city" placeholder="City" required
						   class="border-2 border-primary-light rounded-md px-1 py-2 placeholder:italic focus:outline-none focus-visible:border-primary-dark w-full" />
				</div>

				<div class="flex-1 flex flex-col space-y-1 mt-6">
					<label for="mobile">Zip Code</label>
					<input type="text" formControlName="zip" name="zip" id="zip" placeholder="Zip Code" required
						   class="border-2 border-primary-light rounded-md px-1 py-2 placeholder:italic focus:outline-none focus-visible:border-primary-dark" />
				</div>
			</div>

			<div class="flex flex-col space-y-1 mt-6">
				<label for="landmark">Landmark</label>
				<input type="text" formControlName="landmark" name="landmark" id="landmark"
					   placeholder="Landmark (Optional)"
					   class="border-2 border-primary-light rounded-md px-1 py-2 placeholder:italic focus:outline-none focus-visible:border-primary-dark" />
			</div>
		</div>
		<div class="mt-4 w-full flex justify-center">
			<button type="submit" pButton class="primary-button" [disabled]="!addressFormGroup.valid">
				Save Address
			</button>
		</div>
	</form>
</div>