import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commaSplitter',
})
export class CommaSplitterPipe implements PipeTransform {
  transform(value: string): any {
    return value.split(',');
  }
}
